<template>
  <div class="purchase">
    <div class="purchase-title">AR采购</div>
    <div class="purchase-content">
      <div class="video"></div>
      <div class="purchase-goods">
        <div class="goods-type">
          <ul>
            <li>热水器</li>
            <li>沙发</li>
          </ul>
        </div>
        <div class="goods-list">
          <el-card
            :body-style="{ padding: '0px' }"
            shadow="never"
            v-for="item in 4"
            :key="item"
          >
            <el-skeleton-item variant="image" />
            <div class="goods-info">
              <span class="goods-title">XXXXXXXXXXXXXXXXXXXXXX</span>
              <div class="price-box">
                <span class="price">￥20000</span>
                <span>销量2000+</span>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang='scss' scoped>
.purchase {
  width: $width;
  margin: 0 auto;
  margin-bottom: 30px;
  .purchase-title {
    font-size: 34px;
    margin: 30px 0;
  }
  .purchase-content {
    .video {
      height: 356px;
      background-color: #777777;
    }
    .purchase-goods {
      @include flexbox(row);
      height: 311px;
      .goods-type {
        width: 225px;
        background-color: #cecece;
        padding: 30px 20px;
        box-sizing: border-box;
      }
      .goods-list {
        flex: 1;
        overflow: auto;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        :deep(.el-card) {
          background-color: #dbdbdb;
          border: none;
          border-radius: 0;
          .el-card__body {
            height: 100%;
            @include flexbox(column);
          }
          .el-skeleton__image {
            height: 207px;
            width: 100%;
          }
          .goods-info {
            flex: 1;
            overflow: auto;
            padding: 5px;
            box-sizing: border-box;
            @include flexbox(column, space-between);
            .goods-title {
              word-wrap: break-word;
            }
            .price-box {
              @include flexbox(row, space-between, center);
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>