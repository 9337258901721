<template>
  <page-view class="provide-mater" :up-class="'provide-mater'">
    <banner :bannerList="bannerList" :textList="materClass" :bannerImg="bannerImg"></banner>

    <div class="texts">
      <div>全国范围内各类材料厂家优选</div>
      <div>
        按品牌道具定位，匹配供应多等级材料，质量可控。<br/>
        所有的材料及产品均符合国家各项标准，性价比高。<br/>
        所有材料及产品源头供应，品质严格把关，价格透明。
      </div>
    </div>

    <div class="twobanner">
      <el-carousel style="height: 100% !important;" trigger="click" arrow="always" :interval="2000" ref="carouselRef">
        <el-carousel-item style="height: 100% !important;" v-for="(item, index) in twobannerList" :key="index">
          <img v-lazy="item.image" width="100%" height="100%" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="product-show">
      <div class="tltle">材料工厂展示</div>

      <div class="look-more">
        <div class="btn" @click="$router.push({ name: 'DesignComAll', params: { companyType: 6 } })">查看更多</div>
      </div>

      <div class="product-show-content">
        <div class="product-item" v-for="item in comList" :key="item.id" @click.stop="toComIndex(item.syscompanyid)">
          <div class="item-name"> {{ item.name }}</div>
          <div class="item-name">{{ item.addressDetail || '' }}</div>
          <div><img :src="item.cmsArticle2[0]?.image || item.cmsArticle1[0]?.image || item.cmsArticle4[0]?.image || item.logo" height="100%" width="100%" /></div>
        </div>
      </div>
      <div style="text-align: center;color: #a5a3a3;">百川智慧商展产业数字化平台</div>
    </div>
    <footer-com></footer-com>
  </page-view>
</template>
<script>
import Banner from "@/components/provideMater/Banner.vue";
import Purchase from "@/components/provideMater/Purchase.vue";
import { mapState, mapActions } from "vuex";
export default {
  name: "",
  components: { Banner, Purchase },
  data() {
    return {
      comList: [],
      materClass: [],
      isShow: true,
      typeIndex: -1,
      bannerList: [],
      twobannerList: [],
      textList: [],
      materContent: {},
      bannerImg: require('../../assets/img/findcai.jpg')
    };
  },
  computed: {
    ...mapState(["screenWidth"]),
    isMobile() {
      if (this.screenWidth < 768) {
        return true;
      } else {
        return false;
      }
    },
    //将分类数组合并成一级二级的二维数组
    materlist() {
      let newArr = [];
      let index = 2;
      while (index < this.materClass.length) {
        newArr.push(this.materClass.slice(index, (index += 2)));
      }
      newArr.unshift([this.materClass[0], this.materClass[1]]);
      return newArr;
    },
  },
  created() {
    this.getMaterList();
    this.getMaterClass();
    this.getImg()
    this.getImgs()
  },
  methods: {

    async getImg() {
      let result = await this.$api.getBanner('PT00012');
      this.bannerList = result.itemList;
    },
    async getImgs() {
      let result = await this.$api.getBanner('PT00688');
      this.twobannerList = result.itemList;
    },
    ...mapActions(["setMaterClassIfy"]),
    //点击类型
    cilckType(val) {
      this.$router.push({
        name: "ProvideMaterAll",
        params: {
          id: val.id,
          pid: val.parentId,
        },
      });
    },
    typeEnter(item, index) {
      this.typeIndex = index;
      this.materContent = item;
      this.isShow = true;
    },
    typeLeave(index) {
      this.isShow = false;
    },
    // 获取材料分类
    async getMaterClass() {
      let result = await this.$api.getMaterClass();
      this.materClass = result.data;
      this.setMaterClassIfy(result.data);
    },
    async getMaterList() {
      let result = await this.$api.selectSn({
        bc_company_type: 6,
        current: 1,
        size: 6,
        s3:0//是优质企业
      });
      this.comList = result.data;
    },

    toComIndex(syscompanyid) {
      const openRoute = this.$router.resolve({
        name: "ComIndex",
        params: {syscompanyid},
      });
      window.open(openRoute.href, "_blank");
    },
    toDetails(v) {
      const openRoute = this.$router.resolve({
        name: "ArticlesDetail",
        params: {
          goodsId: v.id,
        },
      });
      window.open(openRoute.href, "_blank");
    },
  },
};
</script>
<style lang="scss" scoped>
.provide-mater {
  background-color: #f8f9fb;

  .texts {
    font-size: 2.6vw;
    color: rgb(41, 41, 41);
    font-weight: 600;
    width: 80%;
    margin: auto;
    padding: 7% 0%;

    >div:nth-child(2) {
      margin-top: 2%;
      font-weight: 400;
      font-size: 1.5vw;
      line-height: 2;
    }
  }

  .twobanner {
    height: 30vw;

    :deep(.el-carousel__container) {
      height: 100% !important;
    }
  }

  .product-show {
    padding: 5% 0% 4%;
    background-color: #F6F6F6;
    overflow: hidden;

    .tltle {
      font-size: max(16px,3.5vw);
      text-align: center;
    }

    .look-more {
      margin: 0 auto;
      color: #4C9C51;
      text-align: center;

      .btn {
        font-size: 12px;
        padding: 10px 0px;
        cursor: pointer;
      }
    }

    .product-show-content {
      width: 90%;
      margin: 3% auto;
      font-size: 1vw;
      color: #666666;
      white-space: nowrap;
      animation: liner 20s infinite linear;

      @keyframes liner {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-90%);
        }
      }

      &:hover {
        animation-play-state: paused;
        -webkit-animation-play-state: paused;
      }

      .product-item {
        background: #ffffff;
        width: max(150px,30%);
        height: max(100px,20vw);
        padding-top: 2%;
        border-radius: 1vw;
        overflow: hidden;
        display: inline-flex;
        flex-direction: column;
        margin-right: 3%;
        text-align: center;
        cursor: pointer;
        .item-name{
          width: 90%;
          margin: auto;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        >div {
          padding: 1% 0%;
        }

        >div:nth-child(1) {
          font-weight: bold;
        }

        >div:nth-child(2) {
          padding-bottom: 6%;
          font-size: 0.9vw;
        }

        >div:nth-child(3) {
          flex: 1;
          overflow: hidden;
        }
      }
    }
  }
}
</style>