<template>
  <div class="banners">
    <div @mouseenter="move = true">
      <el-carousel trigger="click" arrow="always" :interval="2000" ref="carouselRef" @change="changeBannerAuto">
        <el-carousel-item v-for="item in bannerList" :key="item.id">
          <img v-lazy="item.image" width="100%" height="100%" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div :class="move ? 'hidden_list xian' : 'hidden_list'" @mouseleave="move = false">
      <ul>
        <li v-for="(item, index) in textList" :key="index" @click="cilckType(item)">{{ item.name || item }}</li>
      </ul>
      <div>
        <img :src="bannerImg" />
      </div>
    </div>

    <div class="mask" v-if="move" @click="move = false"></div>
  </div>
</template>
<script>

export default {
  props: ['bannerList', 'textList', 'bannerImg'],
  data() {
    return {
      move: false,
      bannerIndex: 0,
    };
  },
  mounted() {
    let a = document.querySelector('.page-view')
    a.addEventListener('scroll', this.checkedScroll)
  },
  methods: {
    checkedScroll() {
      this.move = false
    },
    changeBannerAuto(index) {
      this.bannerIndex = index;
    },
    cilckType(val) {
      if (this.$route.name == 'PropsMake' ) {
        this.$router.push({
          name: "PropsMakeAll",
          params:{
            value:val
          }
        });
      } else {
        this.$router.push({
          name: "ProvideMaterAll",
          params: {
            id: val.id,
            pid: val.parentId,
          },
        });
      }

    },
  }
};
</script>
<style lang='scss' scoped>
.banners {
  position: relative;

  :deep(.el-carousel) {
    .el-carousel__container {
      height: 21vw;

      .el-carousel__arrow {
        font-size: 28px;
        @include flexbox(row, center, center);
        height: 40px;
        width: 40px;
      }
    }
  }

  .hidden_list {
    height: 21vw;
    width: 100%;
    background-color: white;
    position: absolute;
    top: 0%;
    z-index: 700;
    max-height: 0px;
    overflow: hidden;
    font-weight: bold;
    font-size: 1.2vw;
    transition: all .3s ease-in-out;
    display: flex;
    padding: 0% 15.5%;
    box-sizing: border-box;

    >ul {
      flex: 2;
      position: relative;

      &::after {
        content: '百川优选';
        color: rgb(165, 163, 163);
        position: absolute;
        bottom: 20%;
        left: 2%;
        font-size: 0.8vw;
        font-weight: 400;
      }

      >li {
        color: #424242;
        white-space: nowrap;
        display: inline-block;
        padding: 1% 2%;
        cursor: pointer;

        &:hover {
          color: #439C4C;
        }
      }
    }

    >div {
      flex: 1;
      display: flex;
      justify-content: center;
      margin-left: 5%;

      >img {
        width: 100%;
        height: 80%;
      }
    }

  }

  .xian {
    max-height: 21vw;
  }

  .mask {
    height: 100vh;
    width: 100%;
    background-color: rgba(109, 106, 106, 0.2);
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: 100;
    backdrop-filter: blur(5px);
  }
}
</style>